var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "item-row invoice-item-row" }, [
    _c("td", { attrs: { colspan: "5" } }, [
      _c("table", { staticClass: "full-width" }, [
        _c("colgroup", [
          _c("col", { staticStyle: { width: "40%" } }),
          _vm._v(" "),
          _c("col", { staticStyle: { width: "10%" } }),
          _vm._v(" "),
          _c("col", { staticStyle: { width: "15%" } }),
          _vm._v(" "),
          _vm.discountPerItem === "YES"
            ? _c("col", { staticStyle: { width: "15%" } })
            : _vm._e(),
          _vm._v(" "),
          _c("col", { staticStyle: { width: "15%" } }),
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("td", {}, [
              _c(
                "div",
                { staticClass: "item-select-wrapper" },
                [
                  _c(
                    "div",
                    { staticClass: "sort-icon-wrapper handle" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "sort-icon",
                        attrs: { icon: "grip-vertical" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("item-select", {
                    ref: "itemSelect",
                    style: { "z-index": "9999999999999999" },
                    attrs: {
                      invalid: _vm.$v.item.name.$error,
                      "invalid-description": _vm.$v.item.description.$error,
                      item: _vm.item,
                      "tax-per-item": _vm.taxPerItem,
                      taxes: _vm.item.taxes,
                    },
                    on: {
                      search: _vm.searchVal,
                      select: _vm.onSelectItem,
                      deselect: _vm.deselectItem,
                      onDesriptionInput: function ($event) {
                        return _vm.$v.item.description.$touch()
                      },
                      onSelectItem: function ($event) {
                        _vm.isSelected = true
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "text-right" },
              [
                _c("base-input", {
                  attrs: {
                    invalid: _vm.$v.item.quantity.$error,
                    type: "text",
                    small: "",
                  },
                  on: {
                    keyup: _vm.updateItem,
                    input: function ($event) {
                      return _vm.$v.item.quantity.$touch()
                    },
                  },
                  model: {
                    value: _vm.item.quantity,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "quantity", $$v)
                    },
                    expression: "item.quantity",
                  },
                }),
                _vm._v(" "),
                _vm.$v.item.quantity.$error
                  ? _c("div", [
                      !_vm.$v.item.quantity.maxLength
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(_vm.$t("validation.quantity_maxlength"))
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("td", { staticClass: "text-left" }, [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("div", { staticClass: "flex-fillbd-highlight" }, [
                  _c(
                    "div",
                    { staticClass: "base-input" },
                    [
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "input-field",
                            on: {
                              input: function ($event) {
                                return _vm.$v.item.price.$touch()
                              },
                            },
                            model: {
                              value: _vm.price,
                              callback: function ($$v) {
                                _vm.price = $$v
                              },
                              expression: "price",
                            },
                          },
                          "money",
                          _vm.customerCurrency,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$v.item.price.$error
                    ? _c("div", [
                        !_vm.$v.item.price.maxLength
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                _vm._s(_vm.$t("validation.price_maxlength"))
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.discountPerItem === "YES"
              ? _c("td", {}, [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column bd-highlight" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "btn-group flex-fill bd-highlight",
                          attrs: { role: "group" },
                        },
                        [
                          _c("base-input", {
                            attrs: {
                              invalid: _vm.$v.item.discount_val.$error,
                              "input-class": "item-discount",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.item.discount_val.$touch()
                              },
                            },
                            model: {
                              value: _vm.discount,
                              callback: function ($$v) {
                                _vm.discount = $$v
                              },
                              expression: "discount",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-dropdown",
                            {
                              attrs: { "show-arrow": false, "theme-light": "" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn item-dropdown dropdown-toggle",
                                  attrs: {
                                    slot: "activator",
                                    type: "button",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false",
                                  },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.item.discount_type == "fixed"
                                          ? _vm.currency.symbol
                                          : "%"
                                      ) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-dropdown-item", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.selectFixed.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.$t("general.fixed")) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-dropdown-item", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.selectPercentage.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.$t("general.percentage")) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("td", { staticClass: "text-right" }, [
              _c("div", { staticClass: "item-amount" }, [
                _c("span", [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(_vm.total, _vm.currency)
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "remove-icon-wrapper" },
                  [
                    _vm.showRemoveItemIcon
                      ? _c("font-awesome-icon", {
                          staticClass: "remove-icon",
                          attrs: { icon: "trash-alt" },
                          on: { click: _vm.removeItem },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.taxPerItem === "YES"
            ? _c("tr", { staticClass: "tax-tr" }, [
                _c("td"),
                _vm._v(" "),
                _c(
                  "td",
                  { attrs: { colspan: "4" } },
                  _vm._l(_vm.item.taxes, function (tax, index) {
                    return _c("tax", {
                      key: tax.id,
                      attrs: {
                        index: index,
                        "tax-data": tax,
                        taxes: _vm.item.taxes,
                        "discounted-total": _vm.total,
                        "total-tax": _vm.totalSimpleTax,
                        total: _vm.total,
                        currency: _vm.currency,
                      },
                      on: { update: _vm.updateTax, remove: _vm.removeTax },
                    })
                  }),
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }