<template>
    <div class="setting-main-container">
      <form action="" @submit.prevent="updateUserData">
        <div class="card setting-card">
          <div class="page-header">
            <h3 class="page-title">{{ $t('settings.account_settings.bank_details') }}</h3>
            <p class="page-sub-title">
              {{ $t('settings.account_settings.section_description') }}
            </p>
          </div>
          <div class="row">
            <div class="col-md-6 mb-4 form-group">
              <label class="input-label">{{$tc('settings.account_settings.bank_name')}}</label>
              <base-input
                v-model="formData.bankname"
                :invalid="$v.formData.bankname.$error"
                :placeholder="$t('settings.account_settings.bank_name')"
                @input="$v.formData.bankname.$touch()"
              />
              <div v-if="$v.formData.bankname.$error">
                <span v-if="!$v.formData.bankname.required" class="text-danger">{{ $tc('validation.required') }}</span>
              </div>
            </div>
            <div class="col-md-6 mb-4 form-group">
              <label class="input-label">{{$tc('settings.account_settings.accountno')}}</label>
              <base-input
                v-model="formData.accountno"
                :invalid="$v.formData.accountno.$error"
                :placeholder="$t('settings.account_settings.accountno')"
                @input="$v.formData.accountno.$touch()"
                
              />
              <div v-if="$v.formData.accountno.$error">
                <span v-if="!$v.formData.accountno.required" class="text-danger">{{ $tc('validation.required') }}</span>
              </div>
            </div>
          </div>
          <div class="row  mb-4">
            <div class="col-md-12 input-group">
              <base-button
                :loading="isLoading"
                :disabled="isLoading"
                icon="save"
                color="theme"
                type="submit"
              >
                {{ $tc('settings.account_settings.save') }}
              </base-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </template>
  <script>
  import { validationMixin } from 'vuelidate'
  import { mapActions } from 'vuex'
  import AvatarCropper from 'vue-avatar-cropper'
  const { required, requiredIf, sameAs, email, minLength } = require('vuelidate/lib/validators')
  
  export default {
    components: { AvatarCropper },
    mixins: [validationMixin],
    data () {
      return {
        cropperOutputOptions: {
          width: 150,
          height: 150
        },
        cropperOptions: {
          autoCropArea: 1,
          viewMode: 0,
          movable: true,
          zoomable: true
        },
        formData: {
          name: null,
          email: null,
          password: null,
          confirm_password: null,
          bankname: null,
          accountno: null,
        },
        isLoading: false,
        previewAvatar: null,
        fileObject: null
      }
    },
    validations: {
      formData: {
        name: {
          required
        },
        email: {
          required,
          email
        },
        password: {
          minLength: minLength(5)
        },
        confirm_password: {
          required: requiredIf('isRequired'),
          sameAsPassword: sameAs('password')
        },
        bankname:{
          required: requiredIf('isRequired'),
          minLength: minLength(2)
        },
        accountno:{
          required: requiredIf('isRequired'),
          minLength: minLength(5)
        }
      }
    },
    computed: {
      isRequired () {
        if (this.formData.password === null || this.formData.password === undefined || this.formData.password === '') {
          return false
        }
        return true
      }
    },
    mounted () {
      this.setInitialData();
      this.getbankdetails();
    },
    methods: {
      ...mapActions('userProfile', [
        'loadData',
        'editUser',
        'uploadAvatar'
      ]),
      cropperHandler (cropper) {
        this.previewAvatar = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime)
      },
      setFileObject (file) {
        this.fileObject = file
      },
      handleUploadError (message, type, xhr) {
        window.toastr['error']('Oops! Something went wrong...')
      },
      async getbankdetails(){
          window.axios.get('/api/settings/getbankdetails').then((response) => {
             if(response.status == 200){
              console.log(response.data);
              this.formData.bankname = response.data.data.bankname;
              this.formData.accountno = response.data.data.accno;   
             }else{
              this.formData.bankname = '';
              this.formData.accountno = '';
             }
          }).catch((err) => {
            this.formData.bankname = '';
            this.formData.accountno = '';
          })
      },

      async updatebankdetails(data){
          // console.log(data);
          // console.log("update bank details");
          window.axios.post('/api/settings/updatebankdetails',data).then((response) => {
            // console.log("response data results"); 
            // console.log(response); 
            if (response.data.success) {
                 window.toastr['success']("Updated successfully");
                 this.isLoading = false;
                 return true
            }else{
                 window.toastr['error']("Not Updated");
                 this.isLoading = false;
                 return true
            }
          }).catch((err) => {
              window.toastr['error']("Not Updated");
              this.isLoading = false;
              return true
          })
      },
      

      async setInitialData () {
        let response = await this.loadData()
        this.formData.name = response.data.name
        this.formData.email = response.data.email
        if (response.data.avatar) {
          this.previewAvatar = response.data.avatar
        } else {
          this.previewAvatar = '/images/default-avatar.jpg'
        }
      },
      async updateUserData () {
        this.$v.formData.$touch()
        if (this.$v.$invalid) {
          return true
        }
        this.isLoading = true
        let data = {
          bankname: this.formData.bankname,
          accountno: this.formData.accountno
        }

        this.updatebankdetails(data);
        return;
        

        if (this.formData.password != null && this.formData.password !== undefined && this.formData.password !== '') {
          data = { ...data, password: this.formData.password }
        }
        let response = await this.editUser(data)
        if (response.data.success) {
          this.isLoading = false
          if (this.fileObject && this.previewAvatar) {
            let avatarData = new FormData()
            avatarData.append('admin_avatar', JSON.stringify({
              name: this.fileObject.name,
              data: this.previewAvatar
            }))
            this.uploadAvatar(avatarData)
          }
          window.toastr['success'](this.$t('settings.account_settings.updated_message'))
          return true
        }
        window.toastr['error'](response.data.error)
        return true
      }
    }
  }
  </script>
  