var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "site-footer" }, [
    _c("input", { attrs: { type: "hidden", id: "authtoken" } }),
    _vm._v(" "),
    _c("div", { staticClass: "text-right" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("general.powered_by")) + "\n    "),
      _c("a", { attrs: { href: "www.e2eaccounting.com", target: "_blank" } }, [
        _vm._v("Invexbooks\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }