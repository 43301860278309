var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "expenses main-content" },
    [
      _vm.isdeleting === false
        ? _c(
            "div",
            { staticClass: "alert alert-danger", attrs: { role: "alert" } },
            [_vm._v("\n      Deleting, please wait a moment.\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.$t("expenses.title"))),
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: { slot: "item-title", to: "dashboard" },
                  slot: "item-title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.home")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { slot: "item-title", to: "#" }, slot: "item-title" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("expenses.expense", 2)) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-actions row" },
          [
            _c(
              "div",
              { staticClass: "col-xs-2 mr-4" },
              [
                _c(
                  "base-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.totalExpenses || _vm.filtersApplied,
                        expression: "totalExpenses || filtersApplied",
                      },
                    ],
                    attrs: {
                      outline: true,
                      icon: _vm.filterIcon,
                      size: "large",
                      "right-icon": "",
                      color: "theme",
                    },
                    on: { click: _vm.toggleFilter },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.filter")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "col-xs-2",
                attrs: { slot: "item-title", to: "expenses/create" },
                slot: "item-title",
              },
              [
                _c(
                  "base-button",
                  { attrs: { size: "large", icon: "plus", color: "theme" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("expenses.add_expense")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters",
              },
            ],
            staticClass: "filter-section",
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("expenses.category")))]),
                  _vm._v(" "),
                  _c("base-select", {
                    attrs: {
                      options: _vm.categories,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t(
                        "expenses.categories.select_a_category"
                      ),
                      label: "name",
                    },
                    on: {
                      click: function ($event) {
                        _vm.filter = !_vm.filter
                      },
                    },
                    model: {
                      value: _vm.filters.category,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "category", $$v)
                      },
                      expression: "filters.category",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("expenses.from_date")))]),
                  _vm._v(" "),
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar",
                    },
                    model: {
                      value: _vm.filters.from_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "from_date", $$v)
                      },
                      expression: "filters.from_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("expenses.to_date")))]),
                  _vm._v(" "),
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar",
                    },
                    model: {
                      value: _vm.filters.to_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "to_date", $$v)
                      },
                      expression: "filters.to_date",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "clear-filter", on: { click: _vm.clearFilter } },
              [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          staticClass: "col-xs-1 no-data-info",
          attrs: { align: "center" },
        },
        [
          _c("observatory-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "row", attrs: { align: "center" } }, [
            _c("label", { staticClass: "col title" }, [
              _vm._v(_vm._s(_vm.$t("expenses.no_expenses"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: "description col mt-1",
                attrs: { align: "center" },
              },
              [_vm._v(_vm._s(_vm.$t("expenses.list_of_expenses")))]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "mt-3",
                    attrs: { outline: true, color: "theme", size: "large" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("expenses/create")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("expenses.add_new_expense")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "table-container",
        },
        [
          _c(
            "div",
            { staticClass: "table-actions mt-5" },
            [
              _c("p", { staticClass: "table-stats" }, [
                _vm._v(_vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.expenses.length))]),
                _vm._v(" " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalExpenses))]),
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.selectedExpenses.length
                    ? _c(
                        "v-dropdown",
                        {
                          staticClass: "action mr-5",
                          attrs: { "show-arrow": false, "theme-light": "" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "table-actions-button dropdown-toggle",
                              attrs: { slot: "activator", href: "#" },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item",
                                on: { click: _vm.removeMultipleExpenses },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "trash"] },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectAllFieldStatus,
                  expression: "selectAllFieldStatus",
                },
              ],
              staticClass: "custom-control-input",
              attrs: { id: "select-all", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.selectAllFieldStatus)
                  ? _vm._i(_vm.selectAllFieldStatus, null) > -1
                  : _vm.selectAllFieldStatus,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.selectAllFieldStatus,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.selectAllFieldStatus = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectAllFieldStatus = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectAllFieldStatus = $$c
                    }
                  },
                  _vm.selectAllExpenses,
                ],
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isRequestOngoing,
                    expression: "!isRequestOngoing",
                  },
                ],
                staticClass: "custom-control-label selectall",
                attrs: { for: "select-all" },
              },
              [
                _c("span", { staticClass: "select-all-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.select_all")) + " "),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          { staticClass: "custom-control custom-checkbox" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectField,
                                  expression: "selectField",
                                },
                              ],
                              staticClass: "custom-control-input",
                              attrs: { id: row.id, type: "checkbox" },
                              domProps: {
                                value: row.id,
                                checked: Array.isArray(_vm.selectField)
                                  ? _vm._i(_vm.selectField, row.id) > -1
                                  : _vm.selectField,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.selectField,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = row.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectField = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectField = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectField = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "custom-control-label",
                              attrs: { for: row.id },
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$tc("expenses.categories.category", 1),
                  "sort-as": "name",
                  show: "category.name",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("expenses.date"),
                  "sort-as": "expense_date",
                  show: "formattedExpenseDate",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("expenses.note"),
                  "sort-as": "expense_date",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("expenses.note")))]),
                        _vm._v(" "),
                        _c("div", { staticClass: "notes" }, [
                          _c("div", { staticClass: "note" }, [
                            _vm._v(_vm._s(row.notes)),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("expenses.amount"),
                  "sort-as": "amount",
                  show: "category.amount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("expenses.amount")))]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.amount,
                                _vm.defaultCurrency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("expenses.action")))]),
                        _vm._v(" "),
                        _c(
                          "v-dropdown",
                          [
                            _c(
                              "a",
                              {
                                attrs: { slot: "activator", href: "#" },
                                slot: "activator",
                              },
                              [_c("dot-icon")],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-dropdown-item",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      to: {
                                        path: "expenses/" + row.id + "/edit",
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "dropdown-item-icon",
                                      attrs: { icon: ["fas", "pencil-alt"] },
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("general.edit")) +
                                        "\n              "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-dropdown-item", [
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeExpense(row.id)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "dropdown-item-icon",
                                    attrs: { icon: ["fas", "trash"] },
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("general.delete")) +
                                      "\n              "
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }